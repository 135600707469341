import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { capitalize } from "lodash";
import { useOktaAuth } from "@okta/okta-react";

import { StyledHomeIcon } from "../../components/Icons";
import HeaderContainer from "../../layout/AppLayout/content/header";
import { PageContextProvider } from "../../layout/AppLayout/context";
import { StyledLayout } from "../../layout/styles";
import Breadcrumbs from "../Shared/Breadcrumbs";
import { StyledPageHeader } from "../styled";
import { StyledTableWrapper } from "../Workbooks/styles";
import { handleErrorMessages } from "../../utils/messages";
import axiosInstance from "./axiosInstance";
import NoPermissions from "../ErrorPages/NoPermissions";
import useQuery from "../../hooks/useQuery";
import useErrorStatus from "../../hooks/useErrorStatus";

const getDetailedUrlLookupKeyName = path => {
  if (!path) return "";
  return path.match(/:\w+/).pop().replace(":", "");
};

const AdminPageLayout = ({ menu }) => {
  const { query, setQuery } = useQuery();
  const navigate = useNavigate();

  const queryParams = useParams();

  const { authState, oktaAuth } = useOktaAuth();
  const { isAuthenticated } = authState || {};
  const isOktaAuthFlow = process.env.REACT_APP_AUTH_FLOW === "okta";
  const accessToken = localStorage.getItem("accessToken");

  const oktaToken = isAuthenticated ? oktaAuth.getAccessToken() : null;
  const token = isOktaAuthFlow
    ? process.env.REACT_APP_FAKE_TOKEN || oktaToken
    : process.env.REACT_APP_FAKE_TOKEN || accessToken;

  const [dataTable, setDataTable] = useState([]);
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const [total, setTotal] = useState(0);
  const [paginationData, setPaginationData] = useState({
    size: "small",
    showSizeChanger: false,
    position: "bottomRight",
    current: Number(query.get("page")) || 1,
    defaultCurrent: 1,
    total: total || dataTable.length || 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const { setErrorStatus, isForbidden } = useErrorStatus();

  const currentPagePath = window.location.pathname;

  const setPage = useCallback(
    currentPage => {
      setQuery("page", currentPage);
    },
    [setQuery],
  );

  const handleGetTableData = useCallback(
    async params => {
      try {
        setIsLoading(true);
        const { data } = await axiosInstance({
          url: activeMenuItem?.url,
          params,
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        });
        setDataTable(data?.items);
        setTotal(data?.total);
      } catch (error) {
        setDataTable([]);
        setTotal(0);
        const response = error?.response || {};
        const { status } = response;
        setErrorStatus(status);
        if (status !== 403) {
          handleErrorMessages(error);
        }
        if (status === 401) {
          navigate("/");
        }
      } finally {
        setIsLoading(false);
      }
    },
    [activeMenuItem?.url, navigate, setErrorStatus, token],
  );

  useEffect(() => {
    if (menu?.length && currentPagePath === "/admin") {
      navigate(`${menu[0].path}`, { replace: true });
      setActiveMenuItem(menu[0]);
    } else if (menu?.length && currentPagePath) {
      const activeItem = menu?.find(data => data?.path === currentPagePath);
      setActiveMenuItem(activeItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu, currentPagePath]);

  useEffect(() => {
    if (activeMenuItem) {
      handleGetTableData(query);
    }
  }, [activeMenuItem, handleGetTableData, query]);

  useEffect(() => {
    setPaginationData(prevState => ({
      ...prevState,
      current: Number(query.get("page")) || 1,
      total,
    }));
  }, [query, total]);

  const config = useMemo(() => {
    let defaultConfig = [
      {
        icon: <StyledHomeIcon />,
        onClick: () => navigate("/"),
      },
      {
        label: "Admin",
        onClick: () => navigate(`${menu[0].path}`),
      },
    ];

    const activeMenuItemData = menu?.find(item =>
      window.location.pathname.includes(item?.path),
    );

    const keyName = getDetailedUrlLookupKeyName(
      activeMenuItemData?.detailPath || "",
    );
    const paramKeyId = queryParams?.[keyName] || "";

    if (paramKeyId) {
      const pathname = window.location.pathname.replace(`/${paramKeyId}/`, "");
      const activeItem = menu?.find(data => data?.path === pathname);

      defaultConfig = [
        ...defaultConfig,
        {
          label: capitalize(activeItem?.label),
          onClick: () => navigate(activeItem.path),
        },
        {
          label: paramKeyId,
        },
      ];
      return defaultConfig;
    }
    defaultConfig = [
      ...defaultConfig,
      {
        label: capitalize(activeMenuItem?.label),
      },
    ];
    return defaultConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenuItem, menu, navigate]);

  if (isForbidden) return <NoPermissions />;

  return (
    <PageContextProvider>
      <HeaderContainer />
      <StyledLayout>
        <StyledPageHeader>
          <Breadcrumbs config={config} />
        </StyledPageHeader>
        <StyledTableWrapper>
          <Outlet
            context={{
              isLoading,
              setPage,
              paginationData,
              dataTable,
              setActiveMenuItem,
              token,
              handleGetTableData,
            }}
          />
        </StyledTableWrapper>
      </StyledLayout>
    </PageContextProvider>
  );
};

AdminPageLayout.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.shape()),
};

export default AdminPageLayout;
