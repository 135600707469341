import { useEffect, useState, useCallback, useMemo, useContext } from "react";
import { useOutletContext } from "react-router";
import { Button, Dropdown, Skeleton, Tooltip, Upload } from "antd";
import {
  CheckSquareOutlined,
  SaveOutlined,
  LoadingOutlined,
  DownOutlined,
} from "@ant-design/icons";

import { useOktaAuth } from "@okta/okta-react";
import { StyledPage, StyledLayout, StyledPageContent } from "../styled";
import { StyledFiltersContainer } from "./styles";
import AgGridContainer from "./DataTable";

import {
  StyledTextButton,
  StyledTextPrimaryButton,
} from "../../components/Buttons/Button.styled";
import { StyledCloseIcon, StyledExcelIcon } from "../../components/Icons";
import NotFound from "../ErrorPages/NotFound";
import NoPermissions from "../ErrorPages/NoPermissions";
import FileUploaderContent from "./Upload/FileUploader";
import { handleErrorMessages, showMessageSuccess } from "../../utils/messages";
import useFiltersData from "../../layout/WorkbookLayout/useFiltersData";
import { bulkUpdateMenuItems } from "./constants";
import { PageContext as ResultDetailsContext } from "../ResultDetails/context";
import { PROGRESS_STATES } from "../../constants/constraints";

const { Dragger } = Upload;

const DataFilters = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const resultDetailsContext = useContext(ResultDetailsContext);
  const { isAuthenticated } = authState || {};
  const isOktaAuthFlow = process.env.REACT_APP_AUTH_FLOW === "okta";
  const accessToken = localStorage.getItem("accessToken");
  const [showBulkUpdateSelect, setShowBulkUpdateSelect] = useState(false);
  const [selectedBulkItem, setSelectedBulkItem] = useState(
    bulkUpdateMenuItems[0],
  );
  const resultStatus = resultDetailsContext.portfolioBuildProgress?.status;
  const isDraft = resultStatus === PROGRESS_STATES.draft;

  const oktaToken = isAuthenticated ? oktaAuth.getAccessToken() : null;
  const token = isOktaAuthFlow
    ? process.env.REACT_APP_FAKE_TOKEN || oktaToken
    : process.env.REACT_APP_FAKE_TOKEN || accessToken;

  const {
    workbookId,
    getResultData,
    isNotFound,
    isForbidden,
    getWorkbookResults,
  } = useOutletContext();

  const filtersData = useFiltersData({
    workbookId,
    refreshPortfolioData: getResultData,
  });

  const {
    gridRef,
    isLoading,
    isSaving,
    data,
    uncheckIncludedLoans,
    onFilteredDataSave,
    onFileUploaded,
  } = filtersData;

  const onBtnExport = () => {
    gridRef.current.api.exportDataAsCsv();
  };

  const [hasData, setHasData] = useState(!!data?.length);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    setHasData(!!data?.length);
  }, [data]);

  const onReuploadClick = useCallback(() => {
    setHasData(false);
  }, []);

  const TableFooter = (
    <>
      {isDraft && (
        <Button
          type="secondary"
          icon={<CheckSquareOutlined />}
          onClick={uncheckIncludedLoans}
        >
          Uncheck all included loans
        </Button>
      )}

      <StyledFiltersContainer>
        {isDraft && (
          <Tooltip
            title="By saving the file you keep your filtering persistent for the future work,
             this operation is permanent and you will need to re-upload a new file to start over."
          >
            <StyledTextPrimaryButton
              type="text"
              icon={<SaveOutlined />}
              onClick={onFilteredDataSave}
              disabled={isSaving}
            >
              {isSaving && <LoadingOutlined />}
              {!isSaving && <>Save</>}
            </StyledTextPrimaryButton>
          </Tooltip>
        )}

        <StyledTextPrimaryButton
          type="text"
          icon={<StyledExcelIcon />}
          onClick={onBtnExport}
        >
          Export
        </StyledTextPrimaryButton>

        {isDraft && (
          <StyledTextButton
            type="text"
            data-cy="reupload-button"
            icon={<StyledCloseIcon />}
            onClick={onReuploadClick}
          >
            Re-Upload File
          </StyledTextButton>
        )}
      </StyledFiltersContainer>
    </>
  );

  const handleFileUpload = useCallback(
    ({ file, fileList }) => {
      const { status } = file;

      if (status === "done") {
        showMessageSuccess({
          text: `${file.name} file uploaded successfully.`,
        });
        onFileUploaded({ refreshData: true });
        getWorkbookResults({ page: 1 });
      } else if (status === "error") {
        const errorDetail = file?.response?.detail;
        handleErrorMessages({
          message: `${file.name} file upload failed. ${errorDetail}`,
        });
      }
      setUploadedFiles(
        fileList.map(thisFile => ({
          ...thisFile,
          error: {
            ...thisFile.error,
            message: `${thisFile.name} file upload failed. ${thisFile?.response?.detail}`,
          },
        })),
      );
    },
    [onFileUploaded, getWorkbookResults],
  );

  const handleBulkMenuClick = useCallback(({ key }) => {
    setSelectedBulkItem(bulkUpdateMenuItems.find(item => item.key === key));
  }, []);

  const handleApplyBulkUpdate = useCallback(() => {
    gridRef.current.api.getSelectedNodes().forEach(node => {
      node.setDataValue("Include", selectedBulkItem.key);
    });
  }, [gridRef, selectedBulkItem]);

  const handleRowSelected = useCallback(({ api }) => {
    setShowBulkUpdateSelect(!!api.getSelectedNodes().length);
  }, []);

  const bulkUpdateSelect = useMemo(() => {
    if (showBulkUpdateSelect) {
      return (
        <Dropdown.Button
          onClick={handleApplyBulkUpdate}
          icon={<DownOutlined />}
          type="primary"
          menu={{
            items: bulkUpdateMenuItems,
            onClick: handleBulkMenuClick,
          }}
        >
          {selectedBulkItem.label}
        </Dropdown.Button>
      );
    }

    return null;
  }, [
    handleApplyBulkUpdate,
    handleBulkMenuClick,
    selectedBulkItem,
    showBulkUpdateSelect,
  ]);

  const draggerOptions = {
    action: `/api/v1/workbooks/${workbookId}/upload/`,
    headers: { Authorization: `Bearer ${token}` },
    onChange: handleFileUpload,
    openFileDialogOnClick: !hasData,
    fileList: uploadedFiles,
    accept: [
      "text/csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
      ".csv",
      ".xlsx",
    ],
  };

  if (isLoading) {
    return (
      <StyledPage grow>
        <StyledLayout>
          <Skeleton active />
        </StyledLayout>
      </StyledPage>
    );
  }

  if (isNotFound) {
    return <NotFound />;
  }

  if (isForbidden) return <NoPermissions />;

  const grid = hasData ? (
    <AgGridContainer
      {...filtersData}
      footerContent={TableFooter}
      showSearch
      type="data-filters"
      onRowSelected={handleRowSelected}
      bulkUpdateSelect={bulkUpdateSelect}
    />
  ) : null;

  return (
    <StyledPage grow>
      <StyledPageContent>
        {isDraft ? (
          <Dragger {...draggerOptions}>
            {!hasData && <FileUploaderContent />}
            {grid}
          </Dragger>
        ) : (
          grid
        )}
      </StyledPageContent>
    </StyledPage>
  );
};

export default DataFilters;
