import PropTypes from "prop-types";
import { useOutletContext, useParams } from "react-router";
import dateFnsGenerateConfig from "rc-picker/lib/generate/dateFns";
import generatePicker from "antd/es/date-picker/generatePicker";
import { useEffect, useState } from "react";
import AceEditor from "react-ace";
import { Checkbox, Form, Input, Tooltip, Upload, message, Avatar } from "antd";
import { isEqual, omit, startCase } from "lodash";

import "brace";
import "brace/mode/json";
import "brace/theme/github";

import axiosInstance from "./axiosInstance";
import { handleErrorMessages } from "../../utils/messages";
import { StyledAvatarContainer, StyledAvatarImg } from "../Settings/styled";
import { StyledDefaultAvatarIcon } from "../../components/Icons";
import {
  StyledFormContainer,
  StyledFormRow,
  StyledFormTitle,
  StyledSaveButtonContainer,
  StyledUploadContainer,
} from "./styled";
import StyledButton from "../../components/Buttons/Button.styled";

const DatePicker = generatePicker(dateFnsGenerateConfig);

const getDetailedUrlLookupKeyName = path => {
  return path.match(/:\w+/).pop().replace(":", "");
};

const DetailedView = ({ config }) => {
  const params = useParams();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const keyName = getDetailedUrlLookupKeyName(config?.detailUrl);
  const paramKeyId = params?.[keyName];

  const [isLoading, setIsLoading] = useState(false);
  const [dataValue, setDataValue] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [fileKey, setFileKey] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [form] = Form.useForm();

  const { token } = useOutletContext();

  const getFileApiUrl = key => {
    return config?.detailSchemaCustomFields?.[key]?.url?.replace(
      `:${keyName}`,
      paramKeyId,
    );
  };

  const getPreparedData = data => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      const currItemType = config?.detailSchema[key];

      if (currItemType?.format === "date-time") {
        return { ...acc, [key]: value ? new Date(value) : null };
      }
      return { ...acc, [key]: value || undefined };
    }, {});
  };

  const onFieldsChange = (_, fields) => {
    const hasErrors = fields.some(item => item?.errors?.length);
    const newData = fields.reduce((acc, curr) => {
      return { ...acc, [curr?.name?.[0]]: curr?.value };
    }, {});
    const isSame = JSON.stringify(newData) === JSON.stringify(dataValue);
    setIsButtonDisabled(isSame || hasErrors);
  };

  const handleGetData = async () => {
    try {
      setIsLoading(true);
      const url = config?.detailUrl?.replace(`:${keyName}`, paramKeyId);
      const { data } = await axiosInstance({
        url,
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });
      const newData = getPreparedData(data);
      setDataValue(newData);
      form.setFieldsValue({ ...newData });
    } catch (error) {
      handleErrorMessages(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteFile = async key => {
    try {
      await axiosInstance({
        url: getFileApiUrl(key),
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDataValue(prevState => ({ ...prevState, profilePicture: null }));
      setPreviewImage("");
      message.success("Profile Picture was deleted!");
    } catch (error) {
      handleErrorMessages(error);
    }
  };

  const handleUploadFile = async key => {
    try {
      const data = new FormData();
      data.append("file", fileData);

      const response = await axiosInstance({
        url: getFileApiUrl(key),
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data,
      });
      setDataValue(prevState => ({
        ...prevState,
        profilePicture: response?.data?.profilePicture,
      }));
      setFileData(null);
      setFileKey("");
      setPreviewImage("");
    } catch (error) {
      handleErrorMessages(error);
    }
  };

  const onFinish = async values => {
    const formValues = omit(dataValue, "profilePicture");
    const isFormChanged = !isEqual(values, formValues);

    const newData = Object.entries(values).reduce((acc, [key, value]) => {
      return { ...acc, [key]: value ?? undefined };
    }, {});
    const url = config?.detailUrl?.replace(`:${keyName}`, paramKeyId);
    try {
      if (isFormChanged) {
        const response = await axiosInstance({
          url,
          method: "PATCH",
          headers: { Authorization: `Bearer ${token}` },
          data: newData,
        });

        const savedData = getPreparedData(response.data);
        setDataValue(savedData);
        form.setFieldsValue({ ...savedData });
      }

      if (fileData) {
        await handleUploadFile(fileKey);
      }
      message.success("Data was updated!");
      setIsButtonDisabled(true);
    } catch (err) {
      const newDataValue = getPreparedData(dataValue);
      form.setFieldsValue({ ...newDataValue });
      setIsButtonDisabled(true);
      handleErrorMessages(err);
    }
  };

  const handleGetField = (type, key) => {
    switch (type) {
      case "string":
        return (
          <Form.Item name={key}>
            <Input
              placeholder={config?.isEditable ? startCase(key) : "-"}
              disabled={!config?.isEditable}
            />
          </Form.Item>
        );
      case "uuid":
        return (
          <Form.Item name={key}>
            <Input
              placeholder={config?.isEditable ? startCase(key) : "-"}
              disabled={!config?.isEditable}
            />
          </Form.Item>
        );
      case "email":
        return (
          <Form.Item
            name={key}
            rules={[
              {
                type: "email",
                message: "The input is not valid Email!",
              },
              {
                required: true,
                message: "Email is required!",
              },
            ]}
          >
            <Input
              placeholder={config?.isEditable ? startCase(key) : "-"}
              disabled={!config?.isEditable}
              type="email"
              required
            />
          </Form.Item>
        );
      case "boolean":
        return (
          <Form.Item name={key} valuePropName="checked">
            <Checkbox disabled={!config?.isEditable} />
          </Form.Item>
        );
      case "date-time":
        return (
          <Form.Item name={key}>
            <DatePicker disabled={!config?.isEditable} />
          </Form.Item>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (paramKeyId) {
      handleGetData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramKeyId]);

  if (isLoading || !dataValue) return null;

  return (
    <>
      <Form form={form} onFinish={onFinish} onFieldsChange={onFieldsChange}>
        {config?.isEditable && (
          <StyledSaveButtonContainer>
            <StyledButton htmlType="submit" disabled={isButtonDisabled}>
              Save Changes
            </StyledButton>
          </StyledSaveButtonContainer>
        )}
        <StyledFormContainer>
          {config?.detailSchema &&
            Object.entries(config?.detailSchema)?.map(([key, value]) => {
              if (key === "payload") return null;
              const valueType = value.format ?? value.type;

              if (valueType === "binary") {
                return (
                  <StyledUploadContainer key={key}>
                    <StyledFormTitle style={{ width: "136px" }}>
                      {startCase(key)}
                    </StyledFormTitle>
                    {dataValue?.[key] || fileData ? (
                      <Avatar
                        size={70}
                        icon={
                          <StyledAvatarImg
                            src={
                              previewImage ||
                              `data:image/jpeg;base64,${dataValue?.[key]}`
                            }
                            alt=""
                          />
                        }
                      />
                    ) : (
                      <StyledAvatarContainer>
                        <StyledDefaultAvatarIcon $width="70px" />
                      </StyledAvatarContainer>
                    )}
                    <Tooltip title="Click on button or drag file to upload profile photo">
                      <Upload
                        customRequest={({ file }) => {
                          const blob = new Blob([file], { type: file.type });
                          setFileData(blob);
                          setFileKey(key);
                          const objectUrl = URL.createObjectURL(file);
                          setPreviewImage(objectUrl);
                          setIsButtonDisabled(false);
                        }}
                        multiple={false}
                        showProgress
                        showUploadList={false}
                        disabled={isLoading}
                      >
                        <StyledButton
                          $autoWidth
                          $lowercase
                          $height={34}
                          $width={180}
                          $padding="6px 12px"
                          htmlType="button"
                          disabled={isLoading}
                        >
                          Upload file
                        </StyledButton>
                      </Upload>
                    </Tooltip>
                    {(dataValue?.[key] || fileData) && (
                      <StyledButton
                        type="secondary"
                        $lowercase
                        $autoWidth
                        $height={34}
                        $padding="6px 12px"
                        htmlType="button"
                        onClick={() => {
                          if (fileData) {
                            setFileData(null);
                            setPreviewImage("");
                            setFileKey("");
                          } else {
                            handleDeleteFile(key);
                          }
                        }}
                        disabled={isLoading}
                      >
                        {fileData ? "Reset" : "Delete"}
                      </StyledButton>
                    )}
                  </StyledUploadContainer>
                );
              }
              return (
                <StyledFormRow key={key}>
                  <StyledFormTitle>{startCase(key)}</StyledFormTitle>
                  {handleGetField(valueType, key)}
                </StyledFormRow>
              );
            })}
        </StyledFormContainer>
      </Form>
      {dataValue?.payload && (
        <>
          <StyledFormTitle style={{ margin: "28px 0px 12px 0px" }}>
            Payload
          </StyledFormTitle>
          <AceEditor
            mode="json"
            theme="github"
            value={`${JSON.stringify(dataValue?.payload, null, "\t")}`}
            readOnly={!config?.isEditable}
            name="PAYLOAD_EDITOR"
            editorProps={{ $blockScrolling: true, wrapEnabled: true }}
            style={{ width: "100%", maxHeight: "50ch" }}
            setOptions={{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              enableSnippets: true,
              showLineNumbers: true,
              tabSize: 2,
            }}
          />
        </>
      )}
    </>
  );
};

DetailedView.propTypes = {
  config: PropTypes.shape(),
};

export default DetailedView;
