import PropTypes from "prop-types";
import { useOutletContext } from "react-router";
import { Table } from "antd";
import _ from "lodash";
import { useCallback, useMemo, useState } from "react";
import Chart from "../../../../../components/Chart/chart";
import { StyledPageContent, StyledPageSection } from "../../../../styled";
import StyledCard from "../../../../../components/Card";
import { StyledChartWrapper } from "../../../styles";
import { PROGRESS_STATES } from "../../../../../constants/constraints";
import ErrorMessagesCard from "../ErrorMessagesCard";
import { parseObjectiveName } from "../../../../../utils/parseObjectiveName";
import { transformFloatingNumbers } from "../../../../../utils/dataFormatters";
import { StyledTextButton } from "../../../../../components/Buttons/Button.styled";
import {
  StyledParametrsIcon,
  StyledHideIcon,
} from "../../../../../components/Icons";
import {
  StyledChartParametrs,
  StyledChartParametrsRow,
} from "../../TradeSummary/Chart/styles";
import Checkbox from "../../../../../components/Form/Checkbox";

const SummaryOverview = ({ isInProgress = false, getTitleDetailsByStatus }) => {
  const [visibleCharts, setVisibleCharts] = useState(["Objective Function"]);
  const [showChartParametrs, setShowChartParametrs] = useState(false);
  const { constraintsData, portfolioResultData, selectedGenerations } =
    useOutletContext();

  const {
    resultList,
    columns,
    getOverviewChartData,
    getStepNumberByGenerationId,
  } = portfolioResultData;
  const {
    constraints,
    objective,
    selectedPortfolio,
    currentObjName,
    isSellReplaceEnabled,
  } = constraintsData;

  const renderedColumns = useMemo(() => {
    const result = isSellReplaceEnabled
      ? columns
      : columns.filter(item => item?.key !== "number_of_transactions");

    return result.filter(item => !["hist", "errors"].includes(item.key));
  }, [columns, isSellReplaceEnabled]);

  const overviewChartData = getOverviewChartData();

  const labelYFormatter = useCallback(
    value => transformFloatingNumbers(value),
    [],
  );

  const {
    status: currentStatus,
    errorMessage,
    failedConstraints,
  } = getTitleDetailsByStatus({
    portfolioUUID: selectedPortfolio?.uuid,
  });

  const hasError =
    currentStatus === PROGRESS_STATES.failed ||
    Object.keys(failedConstraints).length > 0 ||
    errorMessage.length > 0;

  const currentFeaturedSteps = getStepNumberByGenerationId(
    selectedGenerations.split(","),
  );
  const currentObjFuncName = parseObjectiveName(objective);
  const availableCharts = overviewChartData?.series;
  const renderedCharts = useMemo(() => {
    return availableCharts?.length > 1
      ? availableCharts?.filter(item => visibleCharts?.includes(item?.name))
      : availableCharts;
  }, [visibleCharts, availableCharts]);

  return (
    <StyledPageContent $inner>
      {hasError && (
        <StyledPageSection nopadding>
          <ErrorMessagesCard
            constraints={constraints}
            failedConstraints={failedConstraints}
            errorMessage={errorMessage}
          />
        </StyledPageSection>
      )}
      <StyledPageSection nospace>
        <StyledCard
          loading={isInProgress}
          title="Generation Progress"
          actions={
            availableCharts?.length > 1 && (
              <StyledTextButton
                $normal
                $primary
                type="text"
                icon={
                  !showChartParametrs ? (
                    <StyledParametrsIcon />
                  ) : (
                    <StyledHideIcon />
                  )
                }
                onClick={() => setShowChartParametrs(prevState => !prevState)}
              >
                {showChartParametrs ? "Hide Parameters" : "Show Parameters"}
              </StyledTextButton>
            )
          }
        >
          {showChartParametrs && (
            <StyledChartParametrs style={{ top: 0 }}>
              <StyledChartParametrsRow>
                <Checkbox
                  name="all"
                  value={availableCharts?.length === visibleCharts?.length}
                  onChange={() => {
                    const availableChartsKeys = availableCharts?.map(
                      item => item?.name,
                    );
                    setVisibleCharts(prevState => {
                      if (prevState?.length === availableChartsKeys?.length) {
                        return ["Objective Function"];
                      }
                      return availableChartsKeys;
                    });
                  }}
                />
                <div>All charts</div>
              </StyledChartParametrsRow>
              {availableCharts?.map(item => (
                <StyledChartParametrsRow key={item?.name}>
                  <Checkbox
                    value={visibleCharts?.includes(item?.name)}
                    name={item?.name}
                    color={item?.color}
                    onChange={({ target }) => {
                      const { name, checked } = target;
                      if (visibleCharts?.length < 2 && !checked) {
                        return;
                      }
                      setVisibleCharts(prevState => {
                        if (checked) {
                          return [...prevState, name];
                        }
                        return prevState?.filter(value => value !== name);
                      });
                    }}
                  />
                  <div>{item?.name}</div>
                </StyledChartParametrsRow>
              ))}
            </StyledChartParametrs>
          )}
          <StyledChartWrapper data-cy="overview-main-chart">
            {Boolean(overviewChartData?.series?.length) &&
              Boolean(resultList.length) && (
                <Chart
                  chartId={selectedPortfolio?.uuid}
                  seriesData={renderedCharts}
                  customOptions={overviewChartData?.options}
                  xField="step"
                  yField={currentObjFuncName}
                  labelYFormatter={labelYFormatter}
                  customTooltipTitle={currentObjName}
                  lastStepIcon={`${process.env.PUBLIC_URL}/assets/images/icons/emoji_events.svg`}
                  featuredStepIcon={`${process.env.PUBLIC_URL}/assets/images/icons/star.svg`}
                  featuredSteps={currentFeaturedSteps}
                />
              )}
          </StyledChartWrapper>
        </StyledCard>
      </StyledPageSection>

      <StyledPageSection data-cy="overview-table" nospace>
        <StyledCard nopadding loading={isInProgress} title="Portfolios">
          <Table
            columns={renderedColumns}
            dataSource={_.sortBy(resultList, "gen").reverse()}
            pagination={false}
            scroll={{ x: 400 }}
            rowKey="step"
          />
        </StyledCard>
      </StyledPageSection>
    </StyledPageContent>
  );
};

SummaryOverview.propTypes = {
  isInProgress: PropTypes.bool,
  getTitleDetailsByStatus: PropTypes.func,
};

export default SummaryOverview;
